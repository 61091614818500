import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../Service/service";
import { Colors } from "../../Colors";
import "./OrderHistory.css";
export const OrderHistory = () => {
  const [deliveryData, setDeliveryData] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/delivery`)
      .then((response) => {
        setDeliveryData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching delivery data:", error);
      });
  }, []);


  return (
    <div style={{ minHeight: "100vh", width: "100%" }}>
      <div>
        <div style={{ width: "100%", overflowX: "auto" }}>
          <div style={{ overflowX: "auto" }}>
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "black" }}>
                  <th style={tableHeaderStyle}>Order ID</th>
                  <th style={tableHeaderStyle}>Delivery Date</th>
                  <th style={tableHeaderStyle}>Route</th>
                  <th style={tableHeaderStyle}>Delivery User</th>
                  <th style={tableHeaderStyle}>User Data</th>
                  <th style={tableHeaderStyle}>Order Details</th>
                  <th style={tableHeaderStyle}>Payment</th>
                  <th style={tableHeaderStyle}>Status</th>
                </tr>
              </thead>
              <tbody>
                {deliveryData.map((delivery, index) => (
                  <tr key={index}>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      <div>{delivery.order_id}</div>
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      <div>{delivery.deliverydata.currenttime}</div>
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      {delivery.deliverydata.userdata.route}
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      {delivery.deliveryuser}
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      <div style={{ margin: "0", padding: "0" }}>
                        <div>
                          <div>
                            Phone Number : {delivery.deliverydata.userdata.phone_number}
                          </div>
                          <div>
                            Name : {delivery.deliverydata.userdata.first_name}{" "}
                          </div>
                          <div>
                            Email : {delivery.deliverydata.userdata.last_name}
                          </div>
                          <div>
                            House Name : {delivery.deliverydata.userdata.house_name}
                          </div>
                          <div>
                            Land Mark : {delivery.deliverydata.userdata.locality}
                          </div>
                          <div> Road : {delivery.deliverydata.userdata.road_and_location}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      <ul style={{ margin: "0", padding: "0" }}>
                        {delivery.deliverydata.types.map(
                          (deliveryItem, idx) => (
                            <ul key={idx}>
                              <li>
                              <div>Veg Type : {deliveryItem.type}</div>
                                <div>Item Name : {deliveryItem.name}</div>
                                <div>Quantity : {deliveryItem.quantity}</div>
                                <div>Price : ₹ {deliveryItem.price}/-</div>
                                <div>Ingredient : {deliveryItem.ingredient}</div>
                              </li>
                            </ul>
                          )
                        )}
                      </ul>
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      <div>
                        {" "}
                        SubTotal  : ₹{delivery.deliverydata.subtotal}/-
                      </div>
                      <div>
                        {" "}
                        Delivery Charge : ₹{delivery.deliverydata.deliverycharge}/-
                      </div>
                      <div>
                        {" "}
                        Gst : ₹{delivery.deliverydata.gst}/-
                      </div>
                      <div>
                        {" "}
                        Total Price Paid : ₹{delivery.deliverydata.totalpricepaid}/-
                      </div>
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        padding: "8px",
                        color: delivery.status ? "green" : "red",
                      }}
                    >
                      {delivery.status ? "Delivery Completed" : "Pending"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const tableHeaderStyle = {
  border: "1px solid black",
  padding: "8px",
  // backgroundColor: Colors.deepBlue100,
  // color: "white",
  background: Colors.LinearGradient,
  textAlign: "left",
};

export default OrderHistory;
