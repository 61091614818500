import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Table, Badge } from "react-bootstrap";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Star, Users, Clock } from "lucide-react";
import { BASE_URL } from "../../Service/service";
import NavigationBar from "../../NavigationBar";

const API_URL = `${BASE_URL}/deliverycomplete`;

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884D8"];

const RatingAnalyticsDashboard = () => {
  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const response = await fetch(API_URL);
        const data = await response.json();
        setRatings(data);
      } catch (error) {
        console.error("Error fetching ratings:", error);
      }
    };

    fetchRatings();
  }, []);

  const averageRating =
    ratings.reduce((sum, rating) => sum + parseInt(rating.rating), 0) / ratings.length;

  const ratingTypeCounts = ratings.reduce((acc, rating) => {
    acc[rating.delivery_type] = (acc[rating.delivery_type] || 0) + 1;
    return acc;
  }, {});

  const pieChartData = Object.entries(ratingTypeCounts).map(
    ([type, count]) => ({
      name: type,
      value: count,
    })
  );

  const getColorForRating = (rating) => {
    if (rating >= 4) return "success";
    if (rating >= 3) return "warning";
    return "danger";
  };

  return (
    <div>
      {/* <NavigationBar /> */}

      <Container fluid style={{ padding: "1.5rem" }}>
        <h1 style={{ textAlign: "center" }}>Rating Analytics Dashboard</h1>

        <Row>
          <Col md={4}>
            <Card style={dashboardCardStyle}>
              <Card.Body style={{ display: "flex", alignItems: "center" }}>
                <div style={cardIconStyle}>
                  <Star size={24} color="#ffc107" />
                </div>
                <div>
                  <Card.Title>Average Rating</Card.Title>
                  <Card.Text style={{ fontSize: "1.75rem", marginBottom: 0 }}>
                    {averageRating.toFixed(1)}
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card style={dashboardCardStyle}>
              <Card.Body style={{ display: "flex", alignItems: "center" }}>
                <div style={cardIconStyle}>
                  <Users size={24} color="#17a2b8" />
                </div>
                <div>
                  <Card.Title>Total Ratings</Card.Title>
                  <Card.Text style={{ fontSize: "1.75rem", marginBottom: 0 }}>
                    {ratings.length}
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card style={dashboardCardStyle}>
              <Card.Body style={{ display: "flex", alignItems: "center" }}>
                <div style={cardIconStyle}>
                  <Clock size={24} color="#28a745" />
                </div>
                <div>
                  <Card.Title>Latest Rating</Card.Title>
                  <Card.Text style={{ fontSize: "1.75rem", marginBottom: 0 }}>
                    {ratings[0]?.rating || "N/A"}
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Card style={dashboardCardStyle}>
              <Card.Body>
                <Card.Title>Delivery Type Distribution</Card.Title>
                <div style={chartContainerStyle}>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        label={({ name, percent }) =>
                          `${name} ${(percent * 100).toFixed(0)}%`
                        }
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card style={dashboardCardStyle}>
              <Card.Body>
                <Card.Title>Recent Ratings</Card.Title>
                <div style={tableWrapperStyle}>
                  <div style={tableResponsiveStyle}>
                    <Table
                      hover
                      style={{ borderCollapse: "separate", borderSpacing: 0 }}
                    >
                      <thead style={{ backgroundColor: "#f8f9fa" }}>
                        <tr>
                          <th style={tableCellStyle}>Order ID</th>
                          <th style={tableCellStyle}>Rating</th>
                          <th style={tableCellStyle}>Delivery Type</th>
                          <th style={tableCellStyle}>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ratings.slice(0, 5).map((rating) => (
                          <tr key={rating.id}>
                            <td style={tableCellStyle}>{rating.order_id}</td>
                            <td style={tableCellStyle}>
                              <Badge
                                bg={getColorForRating(parseInt(rating.rating))}
                                style={{
                                  fontSize: "0.8rem",
                                  padding: "0.4em 0.6em",
                                }}
                              >
                                {rating.rating} <Star size={12} />
                              </Badge>
                            </td>
                            <td style={tableCellStyle}>
                              {rating.delivery_type}
                            </td>
                            <td style={tableCellStyle}>
                              {rating.delivery_date}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RatingAnalyticsDashboard;

const dashboardCardStyle = {
  border: "none",
  borderRadius: "15px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease",
  marginBottom: "20px",
  width: "100%",
};

const cardIconStyle = {
  backgroundColor: "#f8f9fa",
  borderRadius: "50%",
  padding: "10px",
  marginRight: "15px",
};

const tableWrapperStyle = {
  maxHeight: "300px",
  overflowY: "auto",
};

const tableResponsiveStyle = {
  overflowX: "auto",
};

const tableCellStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "150px",
};

const chartContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "300px",
};
