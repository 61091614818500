import React, { useState } from "react";
import { BASE_URL } from "../../Service/service";
import NavigationBar from "../../NavigationBar";
import { Colors } from "../../Colors";

const UpdatePush = () => {
  const [alertId, setAlertId] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [version, setVersion] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [updateUrl, setUpdateUrl] = useState("");
  const [dismissable, setDismissable] = useState(true);
  const [getResponse, setGetResponse] = useState("");
  const [playStoreVersion, setPlayStoreVersion] = useState("");
  const [deleteVersion, setDeleteVersion] = useState("");
  const handlePostPush = async () => {
    try {
      const response = await fetch(`${BASE_URL}/updatepush`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          alert_id: alertId,
          title,
          message,
          version,
          timestamp,
          update_url: updateUrl,
          dismissable,
        }),
      });
      if (response.ok) {
        alert("Push notification sent successfully!");
      } else {
        alert("Failed to send push notification");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while sending the push notification");
    }
  };

  const handleGetPush = async () => {
    try {
      const response = await fetch(`${BASE_URL}/updatepush`);
      const data = await response.json();
      setGetResponse(JSON.stringify(data, null, 2));
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while fetching push notifications");
    }
  };
  const handleDeletePush = async () => {
    if (!deleteVersion) {
      alert("Please enter a version to delete");
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/updatepush`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ version: deleteVersion }),
      });
      if (response.ok) {
        const data = await response.json();
        alert(data.message);
        setDeleteVersion("");
      } else {
        alert("Failed to delete push notification");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while deleting the push notification");
    }
  };


  const inputStyle = {
    width: "100%",
    padding: "8px",
    margin: "8px 0",
    boxSizing: "border-box",
    borderRadius: "4px",
    border: "1px solid #ccc",
  };

  const buttonStyle = {
    width: "100%",
    padding: "10px",
    margin: "8px 0",
    background: Colors.LinearGradient,
    color: "black",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  };
  const buttonStyleGet = {
    width: "100%",
    padding: "10px",
    margin: "8px 0",
    background: "green",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  };
  const buttonStyleDelete = {
    width: "100%",
    padding: "10px",
    margin: "8px 0",
    background: "red",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  };

  return (
    <div>
      {/* <NavigationBar /> */}
      <div style={{ maxWidth: "600px", margin: "0 auto", padding: "20px" }}>
        <h1 style={{ textAlign: "center",marginBottom:24 }}>
        Play Store Update
        </h1>

        <div>
          <h2>Send Push Notification</h2>
          <input
            style={inputStyle}
            placeholder="Alert "
            value={alertId}
            onChange={(e) => setAlertId(e.target.value)}
          />
          <input
            style={inputStyle}
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <input
            style={inputStyle}
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <input
            style={inputStyle}
            placeholder="Version"
            value={version}
            onChange={(e) => setVersion(e.target.value)}
          />
          <input
            style={inputStyle}
            type="datetime-local"
            value={timestamp}
            onChange={(e) => setTimestamp(e.target.value)}
          />
          <input
            style={inputStyle}
            placeholder="Update URL"
            value={updateUrl}
            onChange={(e) => setUpdateUrl(e.target.value)}
          />
          <div>
            <label>
              <input
                type="checkbox"
                checked={dismissable}
                onChange={(e) => setDismissable(e.target.checked)}
              />{" "}
              Dismissable
            </label>
          </div>
          <button style={buttonStyle} onClick={handlePostPush}>
            Send Push Notification
          </button>
        </div>

        <div>
          <h2>Get Push Notifications</h2>
          <button style={buttonStyleGet} onClick={handleGetPush}>
            Fetch Push Notifications
          </button>
          {getResponse && (
            <pre
              style={{
                backgroundColor: "#f0f0f0",
                padding: "10px",
                borderRadius: "4px",
                overflowX: "auto",
              }}
            >
              {getResponse}
            </pre>
          )}
        </div>

        <div>
          <h2>Delete Push Notification</h2>
          <input
            style={inputStyle}
            placeholder="Version to delete"
            value={deleteVersion}
            onChange={(e) => setDeleteVersion(e.target.value)}
          />
          <button
            style={{ ...buttonStyleDelete}}
            onClick={handleDeletePush}
          >
            Delete Push Notification
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdatePush;
