// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import "./PaymentScreen.css";
// import { BASE_URL } from "../../Service/service";
// import NavigationBar from "../../NavigationBar";
// import { Colors } from "../../Colors";
// import MonthlyReport from "./MonthlyReport";
// const PaymentScreen = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [totalAmount, setTotalAmount] = useState(0);
//   const [showDateRangeReport, setShowDateRangeReport] = useState(false);
//   const [activeComponent, setActiveComponent] = useState("update");
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${BASE_URL}/createSetupMeals`);
//         calculateTotal(response.data);
//         setData(response.data);
//         setLoading(false);
//       } catch (err) {
//         setError(err);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const calculateTotal = (orders) => {
//     const total = orders.reduce(
//       (acc, order) =>
//         acc + parseFloat(order.subscription_details.totalPricePaid),
//       0
//     );
//     setTotalAmount(total.toFixed(2));
//   };
//   const handleRefund = async (orderId) => {
//     const confirmRefund = window.confirm(
//       `Are you sure you want to initiate a refund for order ID: ${orderId}?`
//     );
//     if (!confirmRefund) return;

//     try {
//       await axios.put(`${BASE_URL}/updateOrderStatus/${orderId}`, {
//         orderStatus: 6,
//       });
//       setData((prevData) =>
//         prevData.map((order) =>
//           order.orderid === orderId ? { ...order, order_status: 6 } : order
//         )
//       );
//       alert(`Refund initiated for order ID: ${orderId}`);
//     } catch (err) {
//       alert(`Failed to initiate refund for order ID: ${orderId}`);
//     }
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error.message}</div>;

//   return (
//     <div style={{ minHeight: "100vh" }}>
//       <NavigationBar />
//       <div className="payment-screen">
//         <h1
//           style={{ textAlign: "center", marginBottom: "20px", padding: "20px" }}
//         >
//           Payment Overview
//         </h1>
//         <div style={styles.actionToggle}>
//           <div
//             style={{
//               ...styles.toggleButton,
//               ...(activeComponent === "update" ? styles.activeButton : {}),
//               borderTopLeftRadius: "8px",
//               borderBottomLeftRadius: "8px",
//             }}
//             onClick={() => setActiveComponent("update")}
//           >
//             Subscription
//           </div>
//           <div
//             style={{
//               ...styles.toggleButton,
//               ...(activeComponent === "pause" ? styles.activeButton : {}),
//               borderTopRightRadius: "8px",
//               borderBottomRightRadius: "8px",
//             }}
//             onClick={() => setActiveComponent("pause")}
//           >
//             Addon
//           </div>
//         </div>
// {/* Subscription container */}
//         <div>
//           <button
//             onClick={() => setShowDateRangeReport(!showDateRangeReport)}
//             style={{
//               padding: "10px 20px",
//               fontSize: "16px",
//               background: Colors.LinearGradient,
//               color: "black",
//               border: "none",
//               borderRadius: "4px",
//               cursor: "pointer",
//               marginBottom: "20px",
//             }}
//           >
//             {showDateRangeReport ? "Hide Report" : "Show Report"}
//           </button>

//           {showDateRangeReport && <MonthlyReport data={data} />}
//           <table>
//             <thead>
//               <tr>
//                 <th style={tableHeaderStyle}>No</th>
//                 <th style={tableHeaderStyle}>Date & Time</th>
//                 <th style={tableHeaderStyle}>First Name</th>
//                 <th style={tableHeaderStyle}>Phone Number</th>
//                 <th style={tableHeaderStyle}>Order ID</th>
//                 {/* <th>Subscription Status</th> */}
//                 <th style={tableHeaderStyle}>Start Date</th>
//                 <th style={tableHeaderStyle}>End Date</th>
//                 <th style={tableHeaderStyle}>Transaction ID</th>
//                 <th style={tableHeaderStyle}>Total Price Paid ₹</th>
//                 <th style={tableHeaderStyle}>Refund Request</th>
//               </tr>
//             </thead>
//             <tbody>
//               {data.map((order, index) => (
//                 <tr key={order.subscription_details.startDate}>
//                   <td>{index + 1}</td>
//                   <td>{order.subscription_details.currentTime}</td>
//                   <td>{order.userdata.first_name}</td>
//                   <td>{order.userdata.phone_number}</td>
//                   <td>{order.orderid}</td>
//                   {/* <td>{order.subscription_status}</td> */}
//                   <td>{order.subscription_details.startDate}</td>
//                   <td>{order.subscription_details.endDate}</td>
//                   <td>{order.subscription_details.transactionId}</td>
//                   <td>{order.subscription_details.totalPricePaid}</td>
//                   <td>
//                     {order.order_status === 2 ? (
//                       <span>
//                         {order.subscription_status === "Active"
//                           ? "Payment complete"
//                           : order.subscription_status}
//                       </span>
//                     ) : order.order_status === 4 ? (
//                       <div
//                         onClick={() => handleRefund(order.orderid)}
//                         style={{
//                           backgroundColor: "red",
//                           color: "white",
//                           padding: "5px 10px",
//                           border: "none",
//                           borderRadius: "5px",
//                           cursor: "pointer",
//                           // display: "inline-block",
//                           textAlign: "center",
//                           // fontSize: "16px",
//                           fontWeight: "bold",
//                         }}
//                       >
//                         Order Cancelled by user
//                       </div>
//                     ) : order.order_status === 6 ? (
//                       "Refund Complete"
//                     ) : (
//                       "N/A"
//                     )}
//                   </td>
//                 </tr>
//               ))}
//               <tr>
//                 <td
//                   colSpan="8"
//                   style={{
//                     ...tableCellStyle,
//                     textAlign: "right",
//                     fontWeight: "bold",
//                   }}
//                 >
//                   Total:
//                 </td>
//                 <td style={{ ...tableCellStyle, fontWeight: "bold" }}>
//                   ₹{totalAmount}
//                 </td>
//                 <td style={tableCellStyle}></td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PaymentScreen;

// const tableHeaderStyle = {
//   border: "1px solid #dddddd",
//   padding: "12px",
//   backgroundColor: Colors.deepBlue100,
//   color: "white",
//   textAlign: "left",
// };

// const tableCellStyle = {
//   border: "1px solid #dddddd",
//   padding: "8px",
//   textAlign: "left",
// };

// const styles = {
//   actionToggle: {
//     display: "flex",
//     justifyContent: "center",
//     marginBottom: "20px",
//   },
//   toggleButton: {
//     padding: "10px 20px",
//     border: "1px solid #FAC105",
//     background: "white",
//     color: "black",
//     cursor: "pointer",
//   },
//   activeButton: {
//     background: Colors.LinearGradient,
//     color: "white",
//   },
// };

import React, { useEffect, useState } from "react";
import axios from "axios";
import "./PaymentScreen.css";
import { BASE_URL } from "../../Service/service";
import { Colors } from "../../Colors";
import MonthlyReport from "./MonthlyReport";
import AddonContainerPayment from "./AddonContainerPayment";

const PaymentScreen = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showDateRangeReport, setShowDateRangeReport] = useState(false);
  const [activeComponent, setActiveComponent] = useState("subscription");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/createSetupMeals`);
        const sortedData = response.data.sort((a, b) => 
          new Date(b.subscription_details.currentTime) - new Date(a.subscription_details.currentTime)
        );
        calculateTotal(sortedData);
        setData(sortedData);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateTotal = (orders) => {
    const total = orders.reduce(
      (acc, order) =>
        acc + parseFloat(order.subscription_details.totalPricePaid),
      0
    );
    setTotalAmount(total.toFixed(2));
  };

  const handleRefund = async (orderId) => {
    const confirmRefund = window.confirm(
      `Are you sure you want to initiate a refund for order ID: ${orderId}?`
    );
    if (!confirmRefund) return;

    try {
      await axios.put(`${BASE_URL}/updateOrderStatus/${orderId}`, {
        orderStatus: 6,
      });
      setData((prevData) =>
        prevData.map((order) =>
          order.orderid === orderId ? { ...order, order_status: 6 } : order
        )
      );
      alert(`Refund initiated for order ID: ${orderId}`);
    } catch (err) {
      alert(`Failed to initiate refund for order ID: ${orderId}`);
    }
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const time = `${hours}:${minutes}:${seconds} ${ampm}`;
    const formattedDate = `${day}/${month}/${year}`;

    return `${formattedDate} ${time}`;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{ minHeight: "100vh" }}>
      <div className="payment-screen">
        <h1 style={styles.title}>Payment Overview</h1>
        <div style={styles.actionToggle}>
          <div
            style={{
              ...styles.toggleButton,
              ...(activeComponent === "subscription"
                ? styles.activeButton
                : {}),
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
            }}
            onClick={() => setActiveComponent("subscription")}
          >
            Subscription
          </div>
          <div
            style={{
              ...styles.toggleButton,
              ...(activeComponent === "addon" ? styles.activeButton : {}),
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
            }}
            onClick={() => setActiveComponent("addon")}
          >
            Addon
          </div>
        </div>

        {activeComponent === "subscription" ? (
          <div>
            <button
              onClick={() => setShowDateRangeReport(!showDateRangeReport)}
              style={styles.reportButton}
            >
              {showDateRangeReport ? "Hide Report" : "Show Report"}
            </button>

            {showDateRangeReport && <MonthlyReport data={data} />}
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.tableHeader}>No</th>
                  <th style={styles.tableHeader}>Date & Time</th>
                  <th style={styles.tableHeader}>First Name</th>
                  <th style={styles.tableHeader}>Phone Number</th>
                  <th style={styles.tableHeader}>Order ID</th>
                  <th style={styles.tableHeader}>Start Date</th>
                  <th style={styles.tableHeader}>End Date</th>
                  <th style={styles.tableHeader}>Transaction ID</th>
                  <th style={styles.tableHeader}>Total Price Paid ₹</th>
                  <th style={styles.tableHeader}>Refund Request</th>
                </tr>
              </thead>
              <tbody>
                {data.map((order, index) => (
                  <tr key={order.subscription_details.startDate}>
                    <td style={styles.tableCell}>{index + 1}</td>
                    <td style={styles.tableCell}>
                      {formatDateTime(order.subscription_details.currentTime)}
                    </td>
                    <td style={styles.tableCell}>
                      {order.userdata.first_name}
                    </td>
                    <td style={styles.tableCell}>
                      {order.userdata.phone_number}
                    </td>
                    <td style={styles.tableCell}>{order.orderid}</td>
                    <td style={styles.tableCell}>
                      {order.subscription_details.startDate}
                    </td>
                    <td style={styles.tableCell}>
                      {order.subscription_details.endDate}
                    </td>
                    <td style={styles.tableCell}>
                      {order.subscription_details.transactionId}
                    </td>
                    <td style={styles.tableCell}>
                      ₹{order.subscription_details.totalPricePaid}
                    </td>
                    <td style={styles.tableCell}>
                      {order.order_status === 2 ? (
                        <span>
                          {order.subscription_status === "Active"
                            ? "Payment complete"
                            : order.subscription_status}
                        </span>
                      ) : order.order_status === 4 ? (
                        <div
                          onClick={() => handleRefund(order.orderid)}
                          style={styles.refundButton}
                        >
                          Order Cancelled by user
                        </div>
                      ) : order.order_status === 6 ? (
                        "Refund Complete"
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    colSpan="8"
                    style={{
                      ...styles.tableCell,
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                  >
                    Total:
                  </td>
                  <td style={{ ...styles.tableCell, fontWeight: "bold" }}>
                    ₹{totalAmount}/-
                  </td>
                  <td style={styles.tableCell}></td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <AddonContainerPayment />
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  title: {
    textAlign: "center",
    marginBottom: "20px",
    padding: "20px",
  },
  actionToggle: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  toggleButton: {
    padding: "10px 20px",
    border: "1px solid #FAC105",
    background: "white",
    color: "black",
    cursor: "pointer",
  },
  activeButton: {
    background: Colors.LinearGradient,
    color: "black",
  },
  reportButton: {
    padding: "10px 20px",
    fontSize: "16px",
    background: Colors.LinearGradient,
    color: "black",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginBottom: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeader: {
    border: "1px solid #dddddd",
    padding: "12px",
    backgroundColor: Colors.deepBlue100,
    color: "black",
    textAlign: "left",
  },
  tableCell: {
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
  },
  refundButton: {
    backgroundColor: "red",
    color: "white",
    padding: "5px 10px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "center",
    fontWeight: "bold",
  },
  addonContainer: {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#f0f0f0",
    borderRadius: "8px",
    margin: "20px 0",
  },
};

export default PaymentScreen;
