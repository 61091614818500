import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import { BASE_URL } from "../../Service/service";
import NavigationBar from "../../NavigationBar";
import { Colors } from "../../Colors";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/users`);
        var sortedResponse = response.data;
        sortedResponse.sort((a, b) => a.id - b.id);
        setUsers(sortedResponse);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return new Date(dateString).toLocaleString("en-US", options);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.phone_number.includes(searchTerm)
  );

  return (
    <div style={{ minHeight: "100vh" ,marginBottom:"32px"}}>
      {/* <NavigationBar /> */}
      <h1
        style={{ textAlign: "center", marginBottom: "0px", padding: "20px" }}
      >
        User Management
      </h1>
      <div style={headerContainerStyle}>
        <div style={searchContainerStyle}>
          <FaSearch style={searchIconStyle} />
          <input
            type="text"
            placeholder="Search by name or phone number"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={searchInputStyle}
          />
        </div>
      </div>

      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>ID</th>
            <th style={tableHeaderStyle}>Name</th>
            <th style={tableHeaderStyle}>Phone Number</th>
            <th style={tableHeaderStyle}>Route</th>
            <th style={tableHeaderStyle}>Email</th>
            <th style={tableHeaderStyle}>Created At</th>
            <th style={tableHeaderStyle}>House Name</th>
            <th style={tableHeaderStyle}>Road & Location</th>
            <th style={tableHeaderStyle}>Locality</th>
            <th style={tableHeaderStyle}>User Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user,index) => (
            <tr key={user.id}>
              <td style={tableCellStyle}>{index+1}</td>
              <td style={tableCellStyle}>{user.first_name}</td>
              <td style={tableCellStyle}>{user.phone_number}</td>
              <td style={tableCellStyle}>{user.route}</td>
              <td style={tableCellStyle}>{user.last_name}</td>
              <td style={tableCellStyle}>{formatDate(user.created_at)}</td>
              <td style={tableCellStyle}>{user.house_name}</td>
              <td style={tableCellStyle}>{user.road_and_location}</td>
              <td style={tableCellStyle}>{user.locality}</td>
              <td style={tableCellStyle}>
                <span
                  style={
                    user.loginstatus ? { color: "green" } : { color: "red" }
                  }
                >
                  {user.loginstatus ? "Logged In" : "Not Logged In"}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const headerContainerStyle = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "20px 32px",
  // marginBottom: "20px",
};

const headerStyle = {
  margin: 0,
};

const searchContainerStyle = {
  display: "flex",
  alignItems: "center",
  width: "300px",
  background: Colors.LinearGradient,
  borderRadius: "8px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  padding: "5px 15px",
};

const searchIconStyle = {
  color: "white",
  marginRight: "10px",
};

const searchInputStyle = {
  flex: 1,
  border: "none",
  padding: "10px",
  fontSize: "16px",
  outline: "none",
  borderRadius: "6px",
};

const tableStyle = {
  borderCollapse: "collapse",
  width: "95%",
  margin: "0 auto",
};

const tableHeaderStyle = {
  border: "1px solid #dddddd",
  padding: "8px",
  background: Colors.LinearGradient,
  fontWeight: "bold",
};

const tableCellStyle = {
  border: "1px solid #dddddd",
  padding: "8px",
};

export default UserManagement;
