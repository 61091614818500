import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CustomConfirmDialog from "./CustomConfirmDialog";
import { Colors } from "../../Colors";
import { BASE_URL } from "../../Service/service";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoicePDF from "./InvoicePDF";
import { Printer } from "lucide-react";
import PDFDownloadButton from "./PDFDownloadButton";

const Addons = () => {
  const [userAddonData, setUserAddonData] = useState([]);
  const [selectedDeliveryUser, setSelectedDeliveryUser] = useState("User-1");
  console.log(userAddonData);

  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/userAddon`);
      const data = await response.json();
      setUserAddonData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formData = {
    deliveryuser: selectedDeliveryUser,
    status: false,
    delivery_type: "Addon",
  };
  const handleClick = (orderId, user, userdata) => {
    showConfirmationDialog(orderId, user, userdata);
  };

  const submitForm = async (orderId, user, userdata) => {
    console.log("userdata", user);
    const updatedFormData = {
      ...formData,
      order_id: orderId,
      deliverydata: user,
      userdata: "",
    };

    try {
      // Submit the form data
      const response = await fetch(`${BASE_URL}/delivery`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedFormData),
      });

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }
      console.log("Form submitted successfully");

      // Update status
      const statusUpdateResponse = await fetch(
        `${BASE_URL}/userAddon/${orderId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: false }),
        }
      );

      if (!statusUpdateResponse.ok) {
        throw new Error("Failed to update status");
      }
      console.log("Status updated successfully");
      fetchData();
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };

  const showConfirmationDialog = (orderId, user, userdata) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomConfirmDialog
            title="Confirm "
            message="Are you sure you want to assign this order to the delivery user?"
            onConfirm={() => {
              onClose();
              submitForm(orderId, user, userdata);
            }}
            onCancel={onClose}
          />
        );
      },
    });
  };

  return (
    <div style={{ marginBottom: "28px", marginLeft: "28px", flex: 1 }}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          // backgroundColor: "white",
        }}
      >
        <thead>
          <tr>
            <th style={tableHeaderStyle}>No.</th>
            <th style={tableHeaderStyle}>Delivery Date</th>
            <th style={tableHeaderStyle}>Delivery Time</th>
            <th style={tableHeaderStyle}>Route</th>
            <th style={tableHeaderStyle}>User Details</th>
            <th style={tableHeaderStyle}>Order Details</th>
            <th style={tableHeaderStyle}>Billing Details</th>
            <th style={tableHeaderStyle}>Delivery User</th>
            <th style={tableHeaderStyle}>Assign</th>
            <th style={tableHeaderStyle}>Print Invoice</th>
          </tr>
        </thead>

        <tbody>
          {userAddonData.map((user, index) => (
            <tr key={index}>
              <td style={tableCellStyle}>{index + 1}</td>
              <td style={tableCellStyle}>{user.currenttime}</td>
              <td style={tableCellStyle}>{user.deliverytime}</td>
              <td style={tableCellStyle}>{user.userdata.route}</td>
              <td style={tableCellStyle}>
                <div>Order Id: {user.orderid}</div>
                <div>
                  Phone : {user.userdata.phone_number}
                  <br />
                  Customer Name : {user.userdata.first_name}
                  <br />
                  Land Mark : {user.userdata.locality}
                  <br />
                  House /Flat : {user.userdata.house_name}
                </div>
              </td>
              <td style={tableCellStyle}>
                {user.types.map(
                  (type, index) => (
                    console.log("mm", user.status),
                    (
                      <div key={index} style={typeContainerStyle}>
                        <div style={imageContainerStyle}>
                          <img
                            src={type.image_url}
                            alt="Food"
                            style={imageStyle}
                          />
                        </div>
                        <div style={typeDetailsStyle}>
                          <div style={lineStyle}>
                            <span
                              style={{
                                ...labelStyle1,
                                color:
                                  type.veg_type === "Veg" ? "green" : "red",
                              }}
                            >
                              Veg Type : {type.type}
                            </span>
                            {type.veg_type}
                          </div>
                          <div style={lineStyle}>
                            <span style={labelStyle1}>Qty :</span>
                            {type.quantity}
                          </div>
                          <div style={lineStyle}>
                            <span style={labelStyle1}>Price :</span>₹{" "}
                            {type.price}/-
                          </div>
                          <div style={lineStyle}>
                            <span style={labelStyle1}>Name :</span>
                            {type.name}
                          </div>
                          {/* <div style={lineStyle}>
                                <span style={labelStyle1}>Type :</span>
                                {type.type}
                              </div> */}
                          <div style={lineStyle}>
                            <span style={labelStyle1}>Ingredient :</span>
                            {type.ingredient}
                          </div>
                        </div>
                      </div>
                    )
                  )
                )}
              </td>
              <td style={tableCellStyle}>
                <div>SubTotal : ₹{user.subtotal}</div>
                <div>Delivery Charge : ₹{user.deliverycharge}</div>
                <div>Gst (5%) : ₹{user.gst}</div>
                <div>Total Price Paid : ₹{user.totalpricepaid}/-</div>
              </td>
              <td style={tableCellStyle}>
                <select
                  value={selectedDeliveryUser}
                  onChange={(e) => setSelectedDeliveryUser(e.target.value)}
                  disabled={!user.status}
                >
                  <option value="">Select User</option>
                  <option value="User-1">User-1</option>
                  <option value="User-2">User-2</option>
                </select>
              </td>
              <td style={tableCellStyle}>
                {user.status ? (
                  <button
                    onClick={() =>
                      handleClick(user.orderid, user, user.userdata)
                    }
                    style={{
                      background: `linear-gradient(${Colors.Primary}, ${Colors.Secondary})`,
                      color: "black",
                      border: "none",
                      fontWeight: "600",
                      borderRadius: "5px",
                      padding: "5px 10px",
                      cursor: "pointer",
                    }}
                  >
                    Done
                  </button>
                ) : (
                  <span>Assigned</span>
                )}
              </td>
              <td style={tableCellStyle}>
                <PDFDownloadButton user={user} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Addons;

const tableHeaderStyle = {
  border: "1px solid black",
  padding: "8px",
  // backgroundColor: Colors.deepBlue100,
  // color: "white",
  textAlign: "left",
  background: Colors.LinearGradient,
};

const tableCellStyle = {
  border: "1px solid black",
  padding: "8px",
};

const lineStyle = {
  marginBottom: "6px",
};

const labelStyle1 = {
  // fontWeight: "bold",
  marginRight: "5px",
};

const typeContainerStyle = {
  display: "flex",
  marginBottom: "8px",
};

const imageContainerStyle = {
  width: "80px",
  height: "80px",
  overflow: "hidden",
  borderRadius: "10px",
  marginRight: "20px",
};

const imageStyle = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  // backgroundColor: "red",
};

const typeDetailsStyle = {
  flexGrow: 1,
};
const printButtonStyle = {
  background: Colors.Primary,
  color: "white",
  border: "none",
  borderRadius: "5px",
  padding: "5px 10px",
  cursor: "pointer",
};
