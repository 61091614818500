import React from 'react';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Printer } from "lucide-react";
import SubscriptionInvoicePDF from "./SubscriptionInvoicePDF";
import { Colors } from "../../Colors";

const PDFDownloadButtonSub = ({ order }) => {
  return (
    <PDFDownloadLink
      document={<SubscriptionInvoicePDF order={order} />}
      fileName={`subscription_invoice_${order.orderid}.pdf`}
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          'Loading document...'
        ) : (
          <button style={printButtonStyle}>
            <Printer size={16} />
            Print 
          </button>
        )
      }
    </PDFDownloadLink>
  );
};

const printButtonStyle = {
    background: Colors.Primary,
    color: "black",
    border: "none",
    borderRadius: "5px",
    padding: "5px 10px",
    cursor: "pointer",
    display: "flex", 
    alignItems: "center", 
};

export default PDFDownloadButtonSub;