import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 30,
  },
  header: {
    fontSize: 24,
    textAlign: "center",
    color: "#000000",
    marginTop: 20,
    textDecoration: "underline",
  },
  companyDetails: {
    fontSize: 10,
    marginBottom: 16,
    textAlign: "center",
  },
  invoiceInfo: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    fontSize: 12,
  },
  addressBlock: {
    marginBottom: 16,
  },
  addressTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
  },
  addressText: {
    fontSize: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#7e1378",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 10,
  },
  tableCellHeader: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 10,
    color: "white",
  },
  totalsContainer: {
    marginTop: 30,
    borderTop: 1,
    borderColor: "#000000",
  },
  totalsRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 5,
  },
  totalsLabel: {
    width: "25%",
    textAlign: "right",
    paddingRight: 8,
    fontSize: 10,
    fontWeight: "bold",
  },
  totalsValue: {
    width: "25%",
    textAlign: "right",
    fontSize: 10,
  },
  grandTotal: {
    marginTop: 10,
    borderTop: 1,
    borderBottom: 1,
    borderColor: "#000000",
    paddingTop: 5,
    paddingBottom: 5,
  },
  grandTotalLabel: {
    width: "25%",
    textAlign: "right",
    paddingRight: 8,
    fontSize: 12,
    fontWeight: "bold",
  },
  grandTotalValue: {
    width: "25%",
    textAlign: "right",
    fontSize: 12,
    fontWeight: "bold",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,
    textAlign: "center",
    fontSize: 10,
    color: "#7e1378",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: "auto",
    width: 100,
    height: 100,
  },
  companyName: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 8,
  },
  authorizedSignatory: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 100,
    fontSize: 10,
    fontWeight: "bold",
  },
});

const SubscriptionInvoicePDF = ({ order }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image
        style={styles.image}
        src="https://i.postimg.cc/ZqchknWV/logorazorpay.jpg"
      />
      <View style={styles.companyDetails}>
        <Text style={styles.companyName}>Lala's Kitchen</Text>
        <Text>Fssai 1132101000848, Pettah, Trivandrum</Text>
        <Text>Phone no.: 9895757524, 7994544423</Text>
        <Text>Email: lalaskitchen.in@gmail.com</Text>
        <View style={styles.header}>
          <Text>SUBSCRIPTION INVOICE</Text>
        </View>
      </View>

      <View style={styles.invoiceInfo}>
        <View>
          <Text>Invoice No: {order.orderid}</Text>
        </View>
        <View>
          <Text>Order Date: {order.subscription_details.currentTime}</Text>
        </View>
      </View>
      <View style={styles.invoiceInfo}>
        <View>
          <Text>
            Subscription Start Date: {order.subscription_details.startDate}
          </Text>
        </View>
        <View>
          <Text>
            Subscription End Date: {order.subscription_details.endDate}
          </Text>
        </View>
      </View>
      <View style={styles.addressBlock}>
        <Text style={styles.addressTitle}>Bill to</Text>
        <Text style={styles.addressText}>
          Name: {order.userdata.first_name}
        </Text>
        <Text style={styles.addressText}>Contact No: {order.uid}</Text>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Meal Type</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Meal Category</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>No. of Persons</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Item Name</Text>
          </View>
        </View>
        {order.subscription_details.tokens.map((token, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{token.menutype}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{token.vegtype}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{token.numberOfMeals}</Text>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                {token.Breakfast === "Yes" && (
                  <View style={styles.mealItem}>
                    <Text style={styles.mealText}>
                      Breakfast : {token.vegtype === "Veg" ? "70" : "70"}/-
                    </Text>
                  </View>
                )}
                {token.Lunch === "Yes" && (
                  <View style={styles.mealItem}>
                    <Text style={styles.mealText}>
                      Lunch : {token.vegtype === "Veg" ? "90" : "110"}/-
                    </Text>
                  </View>
                )}
                {token.Dinner === "Yes" && (
                  <View style={styles.mealItem}>
                    <Text style={styles.mealText}>
                      Dinner : {token.vegtype === "Veg" ? "70" : "70"}/-
                    </Text>
                  </View>
                )}
              </View>
              </View>
          </View>
        ))}
      </View>

      <View style={styles.totalsContainer}>
        <View style={styles.totalsRow}>
          <Text style={styles.totalsLabel}>Sub Total </Text>
          <Text style={styles.totalsValue}>{order.subtotal}</Text>
        </View>

        <View style={styles.totalsRow}>
          <Text style={styles.totalsLabel}>Delivery Charge:</Text>
          <Text style={styles.totalsValue}>{order.deliverycharge}</Text>
        </View>
        <View style={styles.totalsRow}>
          <Text style={styles.totalsLabel}>Discount:</Text>
          <Text style={styles.totalsValue}>{order.discount}</Text>
        </View>

        <View style={[styles.totalsRow, styles.grandTotal]}>
          <Text style={styles.grandTotalLabel}>Total Price Paid:</Text>
          <Text style={styles.grandTotalValue}>
            {order.subscription_details.totalPricePaid}/-
          </Text>
        </View>
      </View>

      <View style={styles.authorizedSignatory}>
        <Text>Authorized Signatory</Text>
      </View>
      <View style={styles.footer}>
        <Text>Thank You for Your Business!</Text>
        <Text>{new Date().toLocaleDateString()}</Text>
      </View>
    </Page>
  </Document>
);

export default SubscriptionInvoicePDF;
