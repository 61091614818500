import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../Service/service";
import { Colors } from "../../Colors";
import PDFDownloadButtonSub from "./PDFDownloadButtonSub";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";

const SubscriptionFilter = ({ onFilterChange, onDateRangeChange, initialStatus }) => {
  const [selectedStatus, setSelectedStatus] = useState(initialStatus);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "end", label: "End" },
    { value: "refund", label: "Refund Completed" },
    { value: "cancelled", label: "Cancelled" },
    { value: "all", label: "All" },
  ];

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    onFilterChange(status);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onDateRangeChange(start, end);
  };

  useEffect(() => {
    onFilterChange(initialStatus);
  }, []);

  return (
    <div style={cardStyle} className="mb-6">
      <div className="flex items-center space-x-2 mb-2">
        <h4 className="text-lg font-semibold text-gray-800">Filter by Order</h4>
      </div>
      <div className="flex flex-wrap gap-2 mb-4">
        {statusOptions.map((option) => (
          <button
            key={option.value}
            onClick={() => handleStatusChange(option.value)}
            style={{
              padding: "0.5rem 1rem",
              borderRadius: "6px",
              fontSize: "0.875rem",
              fontWeight: "500",
              marginRight: "0.5rem",
              backgroundColor:
                selectedStatus === option.value ? "#FAC105" : "grey",
              color: selectedStatus === option.value ? "black" : "white",
              boxShadow:
                selectedStatus === option.value
                  ? "0 4px 6px rgba(0, 0, 0, 0.1)"
                  : "none",
              cursor: "pointer",
            }}
          >
            {option.label}
          </button>
        ))}
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={handleDateChange}
          isClearable={true}
          placeholderText="Select date range"
          className="p-2 border rounded"
        />
      </div>
      {/* <div>
        <h4 className="text-lg font-semibold text-gray-800 mb-2">Date Range</h4>
      </div> */}
    </div>
  );
};

const TokensDisplay = ({ tokens }) => {
  return (
    <div className="tokens-display">
      {tokens.map((token, index) => (
        <div key={index} className="token-item" style={tokenItemStyle}>
          <div style={tokenNumberStyle}>Token {index + 1}</div>
          <div><strong>Category : </strong> {token.vegtype}</div>
          <div><strong>Meal Type : </strong> {token.menutype}</div>
          {token.Breakfast === "Yes" && <div><strong>Breakfast : </strong> {token.Breakfast}</div>}
          {token.Lunch === "Yes" && <div><strong>Lunch : </strong> {token.Lunch}</div>}
          {token.Dinner === "Yes" && <div><strong>Dinner : </strong> {token.Dinner}</div>}
          <div><strong>Persons : </strong> {token.numberOfMeals}</div>
        </div>
      ))}
    </div>
  );
};

function Subscription() {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; 
    const formattedTime = `${hours}:${minutes} ${ampm}`;
    return `${day}/${month}/${year} ${formattedTime}`;
  };

  const [orders, setOrders] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("active"); // Set default to "active"
  const [dateRange, setDateRange] = useState({ start: null, end: null });

  useEffect(() => {
    axios
      .get(`${BASE_URL}/createSetupMeals`)
      .then((response) => {
        let filteredOrders = response.data;

        // Filter by status
        if (currentFilter !== "all") {
          filteredOrders = filteredOrders.filter((order) => {
            const status = getSubscriptionStatus(order);
            if (currentFilter === "active") return status === "Active";
            if (currentFilter === "end") return status === "End";
            if (currentFilter === "refund")
              return status === "Refund Completed";
            if (currentFilter === "cancelled") return status === "Cancelled";
            return true;
          });
        }

        // Filter by date range
        if (dateRange.start && dateRange.end) {
          filteredOrders = filteredOrders.filter((order) => {
            const orderDate = new Date(order.subscription_details.currentTime);
            return (
              orderDate >= dateRange.start && orderDate <= dateRange.end
            );
          });
        }

        // Sort by currentTime
        const sortedOrders = filteredOrders.sort((a, b) => {
          return new Date(b.subscription_details.currentTime) - new Date(a.subscription_details.currentTime);
        });

        setOrders(sortedOrders);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [currentFilter, dateRange]);

  const getSubscriptionStatus = (order) => {
    if (order.order_status === 6) {
      return "Refund Completed";
    }
    if (order.order_status === 4) {
      return "Cancelled";
    }
    return order.subscription_status;
  };

  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
  };

  const handleDateRangeChange = (start, end) => {
    setDateRange({ start, end });
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      <SubscriptionFilter 
        onFilterChange={handleFilterChange} 
        onDateRangeChange={handleDateRangeChange}
        initialStatus="active"  
      />
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={headerStyle}>Name</th>
            <th style={headerStyle}>Route</th>
            <th style={headerStyle}>Phone Number</th>
            <th style={headerStyle}>Order ID</th>
            <th style={headerStyle}>Order Placed Date & Time</th>
            <th style={headerStyle}>Start Date</th>
            <th style={headerStyle}>End Date</th>
            <th style={headerStyle}>Status</th>
            <th style={headerStyle}>Delivery Charge ₹</th>
            <th style={headerStyle}>Total Paid ₹</th>
            <th style={headerStyle}>Tokens</th>
            <th style={headerStyle}>Invoice</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.orderid} style={rowStyle(order)}>
              <td>{order.userdata.first_name}</td>
              <td>{order.userdata.route}</td>
              <td>{order.uid ? order.uid.slice(0, -4) : ""}</td>
              <td>{order.orderid}</td>
              <td>{formatDate(order.subscription_details.currentTime)}</td>
              <td>{order.subscription_details.startDate}</td>
              <td>{order.subscription_details.endDate}</td>
              <td>{getSubscriptionStatus(order)}</td>
              <td>₹ {order.deliverycharge}</td>
              <td>₹ {order.subscription_details.totalPricePaid}</td>
              <td>
                <TokensDisplay tokens={order.subscription_details.tokens} />
              </td>
              <td>
                <PDFDownloadButtonSub order={order} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
  color: "black",
};

const cardStyle = {
  backgroundColor: "white",
  padding: "20px 20px 0px 20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "20px",
  border: "1px solid #D3D3D3",
  width: "fit-content",
};

const headerStyle = {
  background: Colors.LinearGradient,
  padding: "10px",
  textAlign: "left",
};

const tokenItemStyle = {
  backgroundColor: "#f0f0f0",
  padding: "10px",
  borderRadius: "4px",
  marginBottom: "5px",
  position: "relative",
};

const tokenNumberStyle = {
  top: "5px",
  right: "5px",
  backgroundColor: "#FAC105",
  color: "black",
  padding: "2px 6px",
  borderRadius: "3px",
  fontSize: "0.8em",
  fontWeight: "bold",
};

const rowStyle = (order) => {
  let backgroundColor = "white";
  let color = "black";

  if (order.subscription_status !== "Active") {
    backgroundColor = "#D3D3D3";
  }
  if (order.order_status === 6) {
    backgroundColor = "#D3D3D3";
    color = "blue";
  } else if (order.order_status === 4) {
    color = "red";
  } else if (order.subscription_status === "Active") {
    color = "green";
  }

  return {
    backgroundColor,
    color,
  };
};

export default Subscription;