import React, { useState, useEffect } from 'react';
import { LineChart, Line, BarChart, Bar, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Colors } from '../../Colors';

const MonthlyReport = ({ data }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reportData, setReportData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [chartType, setChartType] = useState('line');

  useEffect(() => {
    if (startDate && endDate) {
      const filteredData = data.filter(order => {
        const orderDate = new Date(order.subscription_details.currentTime);
        return orderDate >= new Date(startDate) && orderDate <= new Date(endDate + 'T23:59:59');
      });

      const groupedData = filteredData.reduce((acc, order) => {
        const date = new Date(order.subscription_details.currentTime).toISOString().split('T')[0];
        if (!acc[date]) {
          acc[date] = { date, totalAmount: 0, count: 0 };
        }
        acc[date].totalAmount += parseFloat(order.subscription_details.totalPricePaid);
        acc[date].count += 1;
        return acc;
      }, {});

      const sortedData = Object.values(groupedData).sort((a, b) => new Date(a.date) - new Date(b.date));
      setReportData(sortedData);

      const total = filteredData.reduce((sum, order) => sum + parseFloat(order.subscription_details.totalPricePaid), 0);
      setTotalAmount(total.toFixed(2));
    }
  }, [startDate, endDate, data]);

  const handlePrint = () => {
    window.print();
  };

  const getCurrentDate = () => {
    const now = new Date();
    return now.toISOString().split('T')[0];
  };

  const renderChart = () => {
    const ChartComponent = chartType === 'bar' ? BarChart : chartType === 'area' ? AreaChart : LineChart;
    const DataComponent = chartType === 'bar' ? Bar : chartType === 'area' ? Area : Line;

    return (
      <ResponsiveContainer width="100%" height={300}>
        <ChartComponent data={reportData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
          <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
          <Tooltip />
          <Legend />
          <DataComponent yAxisId="left" type="monotone" dataKey="totalAmount" stroke="#8884d8" fill="#8884d8" name="Daily Revenue" />
          <DataComponent yAxisId="right" type="monotone" dataKey="count" stroke="#82ca9d" fill="#82ca9d" name="Order Count" />
        </ChartComponent>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="monthly-report" style={styles.container}>
      <h2 style={styles.title}>Date Range Report</h2>
      <div style={styles.controls}>
        <div style={styles.dateInputs}>
          <label style={styles.label}>
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              max={getCurrentDate()}
              style={styles.input}
            />
          </label>
          <label style={styles.label}>
            End Date:
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              max={getCurrentDate()}
              style={styles.input}
            />
          </label>
        </div>
        <div style={styles.chartControls}>
          <select
            value={chartType}
            onChange={(e) => setChartType(e.target.value)}
            style={styles.select}
          >
            <option value="line">Line Chart</option>
            <option value="bar">Bar Chart</option>
            <option value="area">Area Chart</option>
          </select>
          <button onClick={handlePrint} style={styles.button}>Print Report</button>
        </div>
      </div>
      {startDate && endDate && reportData.length > 0 && (
        <div style={styles.reportContent}>
          <h3 style={styles.subtitle}>
            Report from {startDate} to {endDate}
          </h3>
          <p style={styles.totalRevenue}>Total Revenue: ₹{totalAmount}</p>
          <div style={styles.chartContainer}>
            {renderChart()}
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    flexWrap: 'wrap',
  },
  dateInputs: {
    display: 'flex',
    gap: '10px',
    marginBottom: '10px',
  },
  chartControls: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
  },
  input: {
    padding: '8px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    marginTop: '4px',
  },
  select: {
    padding: '8px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '8px 16px',
    fontSize: '16px',
    background: Colors.LinearGradient,
    color: 'black',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  reportContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
  },
  subtitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  totalRevenue: {
    fontSize: '18px',
    marginBottom: '20px',
  },
  chartContainer: {
    marginTop: '20px',
  },
};

export default MonthlyReport;