import React, { useState, useEffect } from "react";
import axios from "axios";

const AddonContainerPayment = () => {
  const [addonData, setAddonData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAddonData = async () => {
      try {
        const response = await axios.get(
          "https://lalaskitchen.in:3000/delivery"
        );
        setAddonData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchAddonData();
  }, []);

  if (loading) return <div>Loading addon data...</div>;
  if (error) return <div>Error loading addon data: {error}</div>;
  const totalAmount = addonData.reduce(
    (sum, order) => sum + parseFloat(order.deliverydata.totalpricepaid),
    0
  );
  return (
    <div style={styles.addonContainer}>
      <table style={styles.addonTable}>
        <thead>
          <tr>
            <th style={styles.addonTableHeader}>Order ID</th>
            <th style={styles.addonTableHeader}>Order Date</th>
            <th style={styles.addonTableHeader}>Customer Name</th>
            <th style={styles.addonTableHeader}>Phone Number</th>
            <th style={styles.addonTableHeader}>Delivery Time</th>
            <th style={styles.addonTableHeader}>Items</th>
            <th style={styles.addonTableHeader}>Total Price</th>
          </tr>
        </thead>
        <tbody>
          {addonData.map((order) => (
            <tr key={order.deliverydata.orderid}>
              <td style={styles.addonTableCell}>
                {order.deliverydata.orderid}
              </td>
              <td style={styles.addonTableCell}>
                {order.deliverydata.currenttime}
              </td>
              <td style={styles.addonTableCell}>
                {order.deliverydata.userdata.first_name}
              </td>
              <td style={styles.addonTableCell}>
                {order.deliverydata.userdata.phone_number}
              </td>
              <td style={styles.addonTableCell}>
                {order.deliverydata.deliverytime}
              </td>
              <td style={styles.addonTableCell}>
                <ul style={styles.itemList}>
                  {order.deliverydata.types.map((item, index) => (
                    <li key={index}>
                      {item.name} x{item.quantity} - ₹{item.price}
                    </li>
                  ))}
                </ul>
              </td>
              <td style={styles.addonTableCell}>
                ₹{order.deliverydata.totalpricepaid}
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan="6" style={styles.totalRow}>
              Total Amount :
            </td>
            <td  style={styles.totalRow}>₹{totalAmount}/-</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const styles = {
  addonContainer: {
    // padding: '20px',
    // backgroundColor: '#f8f8f8',
    // borderRadius: '8px',
    // boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  addonTitle: {
    fontSize: "24px",
    marginBottom: "20px",
    color: "#333",
  },
  addonTable: {
    width: "100%",
    borderCollapse: "collapse",
  },
  addonTableHeader: {
    backgroundColor: "#FAC105",
    color: "black",
    padding: "12px",
    textAlign: "left",
  },
  addonTableCell: {
    padding: "12px",
    borderBottom: "1px solid #ddd",
  },
  itemList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  totalRow: {
  fontWeight: 'bold',
  backgroundColor: '#f0f0f0',
  padding: '12px',
},
};

export default AddonContainerPayment;
